import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet";
import { find } from "lodash"
import Layout from "../components/Layout"
import Nav from "../components/Nav"
import SmallPostCard from "../components/SmallPostCard";
import SocialMedia from "../components/SocialMedia";


function pcFeatImg(featuredImage) {
  const result = {
    url: "",
    caption: ""
  }
  if ( featuredImage ) {
    result.url = find(featuredImage?.node?.mediaDetails?.sizes, {name: "smallpostcard-thumb"})?.sourceUrl
    result.caption = featuredImage.node.altText
  }
  return result
}

export default function Home({ data }) {
  const { 0: page } = data.allWpPage.nodes
  const { nodes: recentPosts } = data.allWpPost
    return (
      <Layout>
        <Helmet>
          <title>{data.wp.generalSettings.description}</title>
          <meta name="robots" content="noindex" />
        </Helmet>
        <Nav />

        <div className="md:flex flex-col md:flex-row md:min-h-screen w-full">
          <div className="p-4 flex flex-col w-full md:w-84 text-gray-700
            bg-white dark-mode:text-gray-200 dark-mode:bg-gray-800 flex-shrink-0"
          >
            <h1 className="sm:text-lg md:text-xl lg:text-2xl xl:text-3xl text-red-500 animate-pulse">Error 404 - Page Not Found</h1>
            <br />
            <p>
              Sorry! There doesn&apos;t seem to be a page at this location. Check out my recent blog posts below,
              or see if the search feature can find what you&apos;re looking for.

            </p>
            <hr className="m-4" />
            <h2 className="mb-2">Latest Blog Posts</h2>
            {/* https://tailwindcomponents.com/component/game-lobby for the component starting point */}
            {recentPosts.map((post, index) => {
              return(
                <SmallPostCard
                  key={post.ID || index}
                  title={post.title}
                  excerpt={post.excerpt}
                  featuredImage={pcFeatImg(post.featuredImage)}
                  uri={post.uri}
                />
                )
                }
                )}
            <hr className="m-4" />
            <SocialMedia />

          </div>
        </div>
      </Layout>
    )
  }

export const pageQuery = graphql`
{
  wp {
    generalSettings {
      title
      description
    }
  }
  allWpPage(filter: {isFrontPage: {eq: true}}) {
    nodes {
      title
      content
      seo {
        title
        metaKeywords
        opengraphType
        opengraphTitle
        opengraphDescription
        opengraphUrl
        opengraphSiteName
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphImage {
          link
          mediaDetails {
            width
            height
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          link
        }
      }
    }
  }
  allWpPost(limit: 4, sort: {fields: dateGmt, order: DESC}) {
    nodes {
      id
      title
      slug
      excerpt
      uri
      featuredImage {
        node {
          altText
          mediaDetails {
            sizes {
              file
              height
              name
              sourceUrl
              width
            }
          }
        }
      }
    }
  }
}
`
