import React from "react"

const SocialMedia = () => {
  return (
    <>
      <h3 className="mb-2">Social Media</h3>
      {/* Thanks http://www.entypo.com/ for the social icons */}
      <div className="flex flex-row items-center w-full justify-between">
        <div className="h-12 w-12 m-2">
          <a href="https://twitter.com/nullvariable">
            <svg
              version="1.1"
              id="Twitter"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              enableBackground="new 0 0 20 20"
            >
              <path
                      // eslint-disable-next-line max-len
                d="M17.316,6.246c0.008,0.162,0.011,0.326,0.011,0.488c0,4.99-3.797,10.742-10.74,10.742 c-2.133,0-4.116-0.625-5.787-1.697c0.296,0.035,0.596,0.053,0.9,0.053c1.77,0,3.397-0.604,4.688-1.615 c-1.651-0.031-3.046-1.121-3.526-2.621c0.23,0.043,0.467,0.066,0.71,0.066c0.345,0,0.679-0.045,0.995-0.131 C2.84,11.183,1.539,9.658,1.539,7.828c0-0.016,0-0.031,0-0.047c0.509,0.283,1.092,0.453,1.71,0.473 c-1.013-0.678-1.68-1.832-1.68-3.143c0-0.691,0.186-1.34,0.512-1.898C3.942,5.498,6.725,7,9.862,7.158 C9.798,6.881,9.765,6.594,9.765,6.297c0-2.084,1.689-3.773,3.774-3.773c1.086,0,2.067,0.457,2.756,1.191 c0.859-0.17,1.667-0.484,2.397-0.916c-0.282,0.881-0.881,1.621-1.66,2.088c0.764-0.092,1.49-0.293,2.168-0.594 C18.694,5.051,18.054,5.715,17.316,6.246z"
              />
            </svg>
          </a>
        </div>
        <div className="h-12 w-12 m-2">
          <a href="https://www.linkedin.com/in/dougcone">
            <svg
              version="1.1"
              id="Twitter"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              enableBackground="new 0 0 20 20"
            >
              <path
                      // eslint-disable-next-line max-len
                d="M1,6h4v13H1V6z M3,1C1.8,1,1,2,1,3.1C1,4.1,1.8,5,3,5c1.3,0,2-0.9,2-2C5,1.9,4.2,1,3,1z M14.6,6.2c-2.1,0-3.3,1.2-3.8,2h-0.1l-0.2-1.7H6.9C6.9,7.6,7,8.9,7,10.4V19h4v-7.1c0-0.4,0-0.7,0.1-1c0.3-0.7,0.8-1.6,1.9-1.6c1.4,0,2,1.2,2,2.8V19h4v-7.4C19,7.9,17.1,6.2,14.6,6.2z"
              />
            </svg>
          </a>
        </div>
        <div className="h-12 w-12 m-2">
          <a href="http://www.last.fm/user/nullvariable">
            <svg
              version="1.1"
              id="Twitter"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              enableBackground="new 0 0 20 20"
            >
              <path
                d="M10,0.4c-5.302,0-9.6,4.298-9.6,9.6s4.298,9.6,9.6,9.6s9.6-4.298,9.6-9.6S15.302,0.4,10,0.4z
                      M12.644,12.83 c-2.002,0-2.697-0.91-3.066-2.043L9.207,9.621C8.93,8.77,8.706,8.154,7.688,8.154
                      c-0.706,0-1.626,0.463-1.626,1.898 c0,1.119,0.77,1.82,1.568,1.82c0.677,0,1.084-0.381,
                      1.296-0.572l0.354,1.012c-0.241,0.174-0.734,0.517-1.707,0.517
                      c-1.346,0-2.625-0.957-2.625-2.73c0-1.844,1.333-2.93,2.705-2.93c1.54,0,2.113,0.56,2.6,2.078l0.382,
                      1.166c0.278,0.852,0.865,1.471,2.034,1.471c0.787,0,1.204-0.176,1.204-0.607
                      c0-0.338-0.197-0.584-0.787-0.723l-0.787-0.188
                      c-0.961-0.232-1.343-0.734-1.343-1.529c0-1.271,1.018-1.668,2.061-1.668c1.181,0,1.898,0.432,1.99,
                      1.482L13.85,8.79
                      c-0.046-0.5-0.347-0.711-0.903-0.711c-0.509,0-0.821,0.234-0.821,0.631c0,0.35,0.15,0.559,0.66,
                      0.676l0.74,0.164
                      c0.995,0.233,1.528,0.723,1.528,1.668C15.053,12.387,14.08,12.83,12.644,12.83z"
              />
            </svg>
          </a>
        </div>
      </div>
    </>
  )

}

export default SocialMedia
